export function topbuttonFunc() {
  const pageTop = $("#page-top");

  function PageTopAnime() {
    const scroll = $(window).scrollTop();
    if (scroll >= 200) {
      // 上から200pxスクロールしたら
      pageTop.removeClass("DownMove").addClass("UpMove");
    } else if (pageTop.hasClass("UpMove")) {
      // すでにUpMoveが付いていたらDownMoveに戻す
      pageTop.removeClass("UpMove").addClass("DownMove");
    }
  }

  // 画面をスクロールをしたら動かしたい場合の記述
  $(window).scroll(function () {
    PageTopAnime(); /* スクロールした際の動きの関数を呼ぶ*/
  });

  // #page-topをクリックした際の設定
  $("#page-top a").click(function () {
    $("body,html").animate(
      {
        scrollTop: 0, //ページトップまでスクロール
      },
      500
    ); //ページトップスクロールの速さ。数字が大きいほど遅くなる
    return false; //リンク自体の無効化
  });
}
