export function homeGsapFunc() {
  ////////gsap top//////////

  let h1 = document.querySelector(".p-home__h1");
  let detail = document.querySelector(".p-home__detail");
  let email = document.querySelector(".p-home__email");
  gsap.fromTo(
    [h1, detail, email],
    {
      opacity: 1,
    },
    {
      opacity: 0,
      scrollTrigger: {
        trigger: ".js-trigger1",
        scrub: true,
        start: "top top",
        end: "bottom 15%",
        toggleActions: "play none none none",
      },
    }
  );

  /////////////////////
  ////////gsap img-trigger//////////

  gsap.fromTo(
    ".p-home__second-img-group",
    {
      opacity: 0.1,
    },
    {
      opacity: 0.9,

      scrollTrigger: {
        trigger: ".second-page",
        scrub: true,
        start: "top top",
        end: "20% top",
        // markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    ".p-home__second-img",
    {
      scale: 0.8,
    },
    {
      scale: 1,
      scrollTrigger: {
        trigger: ".second-page",
        scrub: true,
        start: "top top",
        end: "20% top",
        // markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    ".p-home__second-img",
    {
      scale: 1,
    },
    {
      scale: 1.1,
      scrollTrigger: {
        trigger: ".second-page",
        scrub: true,
        start: "20% top",
        end: "52% 0%",
        // markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    ".js-img-trigger-middle",
    {
      y: -100,
    },
    {
      y: 0,
      scrollTrigger: {
        trigger: ".second-page",
        scrub: true,
        start: "top top",
        end: "50% 0%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.fromTo(
    ".js-img-trigger-all",
    {
      y: 70,
    },
    {
      y: -70,
      scrollTrigger: {
        trigger: ".second-page",
        scrub: true,
        start: "top top",
        end: "50% 0%",
        // markers: true,
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.fromTo(
    ".p-home__second-img-group",
    {
      opacity: 0.9,
    },
    {
      opacity: 0.3,
      scrollTrigger: {
        trigger: ".second-page",
        scrub: true,
        start: "20% top",
        // markers: true,
        end: "52% 0%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.fromTo(
    ".p-home__comfortable",
    {
      opacity: 0,
    },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: ".second-page",
        scrub: true,
        start: "35% 30%",
        // markers: true,
        end: "40% top",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.fromTo(
    ".p-home__forth-img",
    {
      opacity: 0,
    },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-home__forth-page",
        scrub: true,
        start: "10% 70%",
        end: "10% 25%",
        // markers: true,
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.to(".p-home__fifth-group", {
    opacity: 1,

    scrollTrigger: {
      trigger: ".p-home__fifth-page",
      start: "42% 41.9%",
      end: "70% 0%",
      // markers: true,
      scrub: true,
      pin: true,
    },
  });

  /* test2 */
  (() => {
    const q = gsap.utils.selector(".p-home__forth-left");
    q(".letter-go").forEach((box, index) => {
      // box
      gsap.from(box, {
        opacity: 0,
        y: 20,
        scrollTrigger: {
          trigger: box,
          start: "top bottom-=50",
          toggleActions: "restart none none none", // onEnter, onLeave, onEnterBack, onLeaveBack
          onLeaveBack: (progress) => {
            // Turn off the balloon when scrolling back.
            gsap.to(progress.trigger, {
              opacity: 0,
              y: 20,
            });
          },
          //markers: true
        },
      });

      // text
      const text = gsap.utils.toArray("span", box);
      gsap.from(text, {
        opacity: 0,
        stagger: 1,
        duration: 0.01,
        scrollTrigger: {
          trigger: box,
          scrub: 0.5,
          ease: "none",
          start: "top bottom-=50",
          end: "bottom bottom",
          //id: 'box-text-'+ index,
          //markers: true,
        },
      });
    });
  })();
}
