export function lifeFunc() {
  ///////////////////////////////////////////////////////////////////////

  ///////////Loading animation(life)///////
  let photoArry = [
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_1.png",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_2.png",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_3.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_4.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_5.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_6.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_7.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_8.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_9.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_10.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_11.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_12.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_13.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_14.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_15.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_16.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_17.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_18.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_19.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_20.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_21.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_22.jpg",
    "https://maisonishihara.com/wp-content/themes/myportfolio/img/loading_life_23.jpg",
  ]; //  画像の配列
  let photo = document.getElementById("photo");
  let photoIndex = Math.floor(Math.random() * photoArry.length);
  photo.src = photoArry[photoIndex];
  $(window).on("load", function () {
    $("#splash-life").delay(1000).fadeOut("slow");
    $("#splash-life-words").delay(1000).fadeOut("slow");
    $("#splash_logo-life").delay(1000).fadeOut("slow");
  });
  let text = "<p>Wait a second.</p>";
  let text1 = document.getElementById("splash-life-words");
  text1.innerHTML = text;

  ///////////////////////画像変更/////////////////////////

  function wineup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_2.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function wineout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_0.png"; // 画像を元に戻す
  }

  function adventureup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_3.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function adventureout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_0.png"; // 画像を元に戻す
  }

  function bookup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_4.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function bookout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_0.png"; // 画像を元に戻す
  }
  function musicup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_5.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function musicout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_0.png"; // 画像を元に戻す
  }
  function visionup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_vision.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function visionout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_0.png"; // 画像を元に戻す
  }
  function personalup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_1.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function personalout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_0.png"; // 画像を元に戻す
  }
  function blogup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_7.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function blogout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_0.png"; // 画像を元に戻す
  }
  function dreamup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_dream.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function dreamout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/myportfolio/img/img_life_0.png"; // 画像を元に戻す
  }
}
