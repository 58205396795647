export function commonFunc() {
  /////////////////////////マウスカーソル////////////////////
  const cursor = document.querySelector("#cursor");
  const stalker = document.querySelector("#stalker");
  const links = document.querySelectorAll("a");

  document.addEventListener("mousemove", (e) => {
    const x = e.clientX;
    const y = e.clientY;
    cursor.style.opacity = "0.9";
    cursor.style.top = `${y}px`;
    cursor.style.left = `${x}px`;
  });

  links.forEach((link) => {
    link.addEventListener("mouseenter", () => {
      cursor.classList.add("active");
    });

    link.addEventListener("mouseleave", () => {
      cursor.classList.remove("active");
    });
  });
  ///////////////////////////////////////////////////////

  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
  for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener("click", (e) => {
      e.preventDefault();
      let href = smoothScrollTrigger[i].getAttribute("href");
      let targetElement = document.getElementById(href.replace("#", ""));
      const rect = targetElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const gap = 60;
      const target = rect + offset - gap;
      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    });
  }
}
