export function wineFunc() {
  var swiper = new Swiper(".p-wine__swiper", {
    effect: "fade",
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 1000,
      disableOnInteraction: false,
    },
    allowTouchMove: false, //マウスでのスワイプを禁止
    fadeEffect: {
      crossFade: true,
    },
  });

  /////////////GSAP////////////////
  gsap.fromTo(
    ".p-wine__swiper",
    {
      backgroundColor: "#fffff5",
    },
    {
      backgroundColor: "#e5e2d2",

      scrollTrigger: {
        trigger: ".p-wine__swiper",
        scrub: true,
        start: "0% 0%",
        //   markers: true,
        end: "100% 10%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
  /////////////////////////////////////////

  /////////////スクロール途中から出現させる/////////////////////////
  const adjust = 50;
  const elem = document.querySelector(".p-wine__second");
  const adjustSecond = -10;
  const poss = elem.getBoundingClientRect().top + window.scrollY;
  const header = document.querySelector(".js-header");

  window.addEventListener("scroll", () => {
    if (window.scrollY + adjust > poss) {
      header.classList.add("show");
    } else {
      header.classList.remove("show");
    }
  });

  window.addEventListener("scroll", () => {
    if (window.scrollY + adjustSecond > poss) {
      document.querySelector(".p-wine__audio").classList.add("audio-open");
    } else {
      document.querySelector(".p-wine__audio").classList.remove("audio-open");
    }
  });

  ////////////flipCard///////////////////////
  const card = document.querySelectorAll(".js-card");

  for (let i = 0; i < card.length; i++) {
    card[i].addEventListener("click", function () {
      card[i].classList.toggle("flipCard");
    });
  }
}
