//トップページ
export function homeFunc() {
  ///////////Loading animation(home)/////
  window.onload = function () {
    const progressBar = document.querySelector(".js-loader__progress-bar");
    const progressNumber = document.querySelector(
      ".js-loader__progress-number"
    );

    const imgLoad = imagesLoaded("body"); //body内の画像を監視
    const imgTotal = imgLoad.images.length; //画像の総数を取得

    let imgLoaded = 0; //ロードした画像カウント
    let progressSpeed = 2; //プログレスバーの進むスピード 大きいほど速くなる
    let progressCount = 0; //ローディングの進捗状況 0〜100
    let progressResult = 0; //ローディングの進捗状況 0〜100

    //読み込み状況をアップデート
    let progressInit = setInterval(function () {
      updateProgress();
    }, 40);

    //画像を一つロードするたびにimgLoadedのカウントをアップ
    imgLoad.on("progress", function (instance, image) {
      imgLoaded++;
    });

    //読み込み状況アップデート関数
    function updateProgress() {
      //ローディング演出用の計算
      progressCount += (imgLoaded / imgTotal) * progressSpeed;

      if (progressCount >= 100 && imgTotal > imgLoaded) {
        //カウントは100以上だが画像の読み込みが終わってない
        progressResult = 99;
      } else if (progressCount > 99.9) {
        //カウントが100以上になった
        progressResult = 100;
      } else {
        //現在のカウント
        progressResult = progressCount;
      }

      //ローディング進捗状況をプログレスバーと数字で表示
      progressBar.style.width = progressResult + "%";
      progressNumber.innerText = Math.floor(progressResult);

      //ローディング完了後 1秒待ってページを表示
      if (progressResult >= 100 && imgTotal == imgLoaded) {
        clearInterval(progressInit);
        // const imgIndex = Math.floor(progressCount / 30); // Display each image for 20% progress
        // imgGroup.innerHTML = `<img src="${imgList[imgIndex]}" alt="" class="js-loader">`;
        setTimeout(function () {
          document.querySelector("body").classList.add("is-loaded");
        }, 3500);
      }
    }
  };
  ////////////////////////////////////////
  //////////////////////////////////////////////////////

  const navAreas = document.querySelector("#navAreas");
  const hamburgersButton = document.querySelector(".l-hamburgers__button");
  const hamburgersMenu = document.querySelector(".l-hamburgers__menu");
  hamburgersButton.addEventListener("click", () => {
    navAreas.classList.toggle("open");
    hamburgersMenu.classList.toggle("push");
  });

  window.addEventListener("scroll", function () {
    const adjust = 120;
    const pos =
      document.querySelector(".p-home__email").getBoundingClientRect().top +
      window.pageYOffset;
    /* mvを過ぎたmainタグの高さを取得して変数[pos]に格納 */
    if (window.pageYOffset + adjust > pos) {
      /* 変数[pos]より、スクロールされていたら */
      document.querySelector(".l-hamburgers__button").classList.add("open");
    } else {
      /* それ以外の場合 */
      document.querySelector(".l-hamburgers__button").classList.remove("open");
    }
  });

  ///////////////////////////////////////////////////////////////////////

  //////////////////////背景色・文字色変更///////////////////
  $(function () {
    $(".p-home__third-card").hover(
      function () {
        $(".p-home__comfortable").addClass("open"); //hoverしたメニューにopenを付与
      },
      function () {
        $(".p-home__comfortable").removeClass("open"); //hoverしたメニューにopenを付与
      }
    );
  });

  $(function () {
    $(".p-home__third-card").hover(
      function () {
        $(".p-home__third-page").addClass("open"); //hoverしたメニューにopenを付与
      },
      function () {
        $(".p-home__third-page").removeClass("open"); //hoverしたメニューにopenを付与
      }
    );
  });

  $(function () {
    $(".p-home__third-card").hover(
      function () {
        $(".p-home__forth-page").addClass("open"); //hoverしたメニューにopenを付与
      },
      function () {
        $(".p-home__forth-page").removeClass("open"); //hoverしたメニューにopenを付与
      }
    );
  });
  //////////////////////////
}
