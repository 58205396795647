export function lifebookFunc() {
  gsap.fromTo(
    ".p-life-book__second-title",
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      y: 0,
      scrollTrigger: {
        trigger: ".p-life-book__second",
        scrub: true,
        start: "20% 70%",
        // markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  const swiper = new Swiper(".swiper", {
    loop: true,
    direction: "horizontal",
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 24,
    grabCursor: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    speed: 5000,
    touchStartPreventDefault: false,
    passiveListeners: false,
    breakpoints: {
      350: {
        slidesPerView: 1.6,
        spaceBetween: 30,
      },
      380: {
        slidesPerView: 1.7,
        spaceBetween: 30,
      },
      400: {
        slidesPerView: 1.7,
        spaceBetween: 50,
      },

      700: {
        slidesPerView: 2.1,
        spaceBetween: 60,
      },
      800: {
        slidesPerView: 2.3,
        spaceBetween: 70,
      },

      1000: {
        slidesPerView: 3,
        spaceBetween: 100,
      },
      1200: {
        slidesPerView: 3.3,
        spaceBetween: 100,
      },

      1500: {
        slidesPerView: 3.7,
        spaceBetween: 150,
      },

      1700: {
        slidesPerView: 4.2,
        spaceBetween: 150,
      },
    },
  });
}
