export function worksFunc() {
  const nav = document.querySelector("#navArea");
  const btn = document.querySelector(".l-hamburger__button");
  const adjust = 120;
  const worksDown = document.querySelector(".p-works__down");
  const pos = worksDown.getBoundingClientRect().top + window.scrollY;

  btn.addEventListener("click", () => {
    nav.classList.toggle("open");
  });

  window.addEventListener("scroll", () => {
    if (window.scrollY + adjust > pos) {
      btn.classList.add("open");
    } else {
      btn.classList.remove("open");
    }
  });

  window.addEventListener("scroll", function () {
    const adjust = 120;
    const pos =
      document.querySelector(".p-works__down").getBoundingClientRect().top +
      window.pageYOffset;
    /* mvを過ぎたmainタグの高さを取得して変数[pos]に格納 */
    if (window.pageYOffset + adjust > pos) {
      /* 変数[pos]より、スクロールされていたら */
      document.querySelector(".l-hamburgers__button").classList.add("open");
    } else {
      /* それ以外の場合 */
      document.querySelector(".l-hamburgers__button").classList.remove("open");
    }
  });

  const navAreas = document.querySelector("#navAreas");
  const hamburgersButton = document.querySelector(".l-hamburgers__button");
  const hamburgersMenu = document.querySelector(".l-hamburgers__menu");
  hamburgersButton.addEventListener("click", () => {
    navAreas.classList.toggle("open");
    hamburgersMenu.classList.toggle("push");
  });

  ///////////////GSAP/////////////////////////

  gsap.fromTo(
    ".bg-black",
    {
      y: 300,
    },
    {
      y: 0,
      scrollTrigger: {
        trigger: ".p-works__down",
        scrub: true,
        start: "0% 70%",
        // markers: true,
        end: "20% 60%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.fromTo(
    ".p-works__h2",
    {
      opacity: 0,
      y: 50,
    },
    {
      opacity: 1,
      y: 50,
      scrollTrigger: {
        trigger: ".p-works__down",
        scrub: true,
        start: "0% 40%",
        // markers: true,
        end: "0% 5%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.fromTo(
    ".p-works",
    {
      backgroundColor: "#fffff5",
    },
    {
      backgroundColor: "#151515",
      scrollTrigger: {
        trigger: ".p-works__box-group",
        scrub: true,
        start: "100% 40%",
        // markers: true,
        end: "100% 5%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  const tl = gsap.timeline();
  tl.pause();
  tl.to(".js-inView", { autoAlpha: 1 }).add(() => {
    document.querySelector(".js-inView span").classList.add("green");
  });

  gsap.to(".js-scale-change", {
    width: window.innerWidth,
    height: window.innerHeight,
    borderRadius: 0,
    scrollTrigger: {
      trigger: ".js-sticky",
      start: "top top",
      ease: "none", //必要！！
      pin: true,
      scrub: 0.8,
      // markers: true,
      onLeave: () => {
        tl.play();
      },
      onEnterBack: () => {
        tl.reverse();
      },
    },
  });

  gsap.fromTo(
    ".p-works__h2",
    {
      y: 50,
    },
    {
      y: 0,
      scrollTrigger: {
        trigger: ".js-sticky",
        start: "top top",
        ease: "none", //必要！！
        scrub: 0.8,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  //////////////////Muuri/////////////////////////////
  $(window).on("load", function () {
    //画面遷移時にギャラリーの画像が被らないように、すべての読み込みが終わった後に実行する

    //＝＝＝Muuriギャラリープラグイン設定
    var grid = new Muuri(".js-grid", {
      //アイテムの表示速度※オプション。入れなくても動作します
      showDuration: 600,
      showEasing: "cubic-bezier(0.215, 0.61, 0.355, 1)",
      hideDuration: 600,
      hideEasing: "cubic-bezier(0.215, 0.61, 0.355, 1)",

      // アイテムの表示/非表示状態のスタイル※オプション。入れなくても動作します
      visibleStyles: {
        opacity: "1",
        transform: "scale(1)",
      },
      hiddenStyles: {
        opacity: "0",
        transform: "scale(0.5)",
      },
    });

    //＝＝＝並び替えボタン設定
    $(".sort-btn li").on("click", function () {
      //並び替えボタンをクリックしたら
      $(".sort-btn .active").removeClass("active"); //並び替えボタンに付与されているactiveクラスを全て取り除き
      var className = $(this).attr("class"); //クラス名を取得
      className = className.split(" "); //「sortXX active」のクラス名を分割して配列にする
      $("." + className[1]).addClass("active"); //並び替えボタンに付与されているクラス名とギャラリー内のリストのクラス名が同じボタンにactiveクラスを付与
      if (className[1] == "sort-all") {
        //クラス名がsort00（全て）のボタンの場合は、
        grid.show(""); //全ての要素を出す
      } else {
        //それ以外の場合は
        grid.filter("." + className[1]); //フィルターを実行
      }
    });
  });
  ////////////////////////////////////////////////////
}
