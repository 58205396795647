export function lifemusicFunc() {
  $(window).on("load", function () {
    //=====ここからローディングエリア（splashエリア）を1.5秒でフェードアウトした後に動かしたいJSをまとめる
    $("#splash")
      .delay(500)

      .fadeOut("slow", function () {
        //ローディングエリア（splashエリア）を1.5秒でフェードアウトする記述
        $("body").addClass("appear"); //フェードアウト後bodyにappearクラス付与
      });
  });

  /////////////アコーディオン////////////////
  $(function () {
    $(".p-life-music__dd").hide();

    $(".p-life-music__dl").click(function () {
      let answer = $(this).children(".p-life-music__dd");
      let plus = $(this).children(".p-life-music__plus");
      let minus = $(this).children(".p-life-music__minus");

      answer.slideToggle();
      plus.toggleClass("block");
      minus.toggleClass("block");
      answer.toggleClass("ouvre");
    });
  });
  //////////////////////////////////////

  gsap.fromTo(
    ".p-life-music__first",
    {
      opacity: 1,
    },
    {
      opacity: 0,

      scrollTrigger: {
        trigger: ".p-life-music__second",
        scrub: true,
        start: "20% 100%",
        //   markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
  gsap.fromTo(
    ".p-life-music__first",
    {
      scale: 1,
    },
    {
      scale: 0.5,

      scrollTrigger: {
        trigger: ".p-life-music__second",
        scrub: true,
        start: "0% 100%",
        //   markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
  gsap.fromTo(
    ".p-life-music__second",
    {
      opacity: 0,
    },
    {
      opacity: 1,

      scrollTrigger: {
        trigger: ".p-life-music__second",
        scrub: true,
        start: "20% 100%",
        //   markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
  gsap.fromTo(
    ".p-life-music__second",
    {
      scale: 0.5,
    },
    {
      scale: 1,

      scrollTrigger: {
        trigger: ".p-life-music__second",
        scrub: true,
        start: "0% 100%",
        //   markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
  gsap.fromTo(
    ".p-life-music__second",
    {
      scale: 1,
    },
    {
      scale: 0.5,

      scrollTrigger: {
        trigger: ".p-life-music__third",
        scrub: true,
        start: "0% 100%",
        // markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
  gsap.fromTo(
    ".p-life-music__second",
    {
      opacity: 1,
    },
    {
      opacity: 0,

      scrollTrigger: {
        trigger: ".p-life-music__third",
        scrub: true,
        start: "0% 100%",
        // markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.fromTo(
    ".p-life-music__third",
    {
      opacity: 0,
    },
    {
      opacity: 1,

      scrollTrigger: {
        trigger: ".p-life-music__third",
        scrub: true,
        start: "0% 100%",
        //   markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
  gsap.fromTo(
    ".p-life-music__third",
    {
      scale: 0.5,
    },
    {
      scale: 1,

      scrollTrigger: {
        trigger: ".p-life-music__third",
        scrub: true,
        start: "0% 100%",
        //   markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );

  gsap.fromTo(
    ".p-life-music__third",
    {
      scale: 1,
    },
    {
      scale: 0.5,

      scrollTrigger: {
        trigger: ".p-life-music__forth",
        scrub: true,
        start: "0% 100%",
        // markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
  gsap.fromTo(
    ".p-life-music__third",
    {
      opacity: 1,
    },
    {
      opacity: 0,

      scrollTrigger: {
        trigger: ".p-life-music__forth",
        scrub: true,
        start: "0% 100%",
        // markers: true,
        end: "20% 40%",
        //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
        toggeleActions: "play reset resume reset",
      },
    }
  );
}
