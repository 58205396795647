export function audioFunc() {
  /////////////////////////////////ミュージック//////////////////
  document.addEventListener("DOMContentLoaded", function () {
    var audioBtn = document.querySelector(".p-audio__button"),
      audioWrap = document.querySelector(".p-audio__button-group"),
      audio = document.getElementById("audio");
    audio.pause();
    audioBtn.addEventListener("click", function () {
      if (audioWrap.classList.contains("play")) {
        audio.pause();
        audioWrap.classList.remove("play");
      } else {
        audio.play();
        audioWrap.classList.add("play");
      }
    });
  });
}
