import "../scss/style.scss";

import { commonFunc } from "./common/_common";
import { animeFunc } from "./common/_anime.min";
import { audioFunc } from "./common/_audio";
import { changescreenFunc } from "./common/_change-screen";
// import { luxyFunc } from "./common/_luxy";
import { navmenuFunc } from "./common/_nav-menu";
import { navmenusFunc } from "./common/_nav-menus";
import { securityFunc } from "./common/_security";
import { topbuttonFunc } from "./common/_topbutton";
// import { pageanimeFunc } from "./common/_pageLinkAnimation";

import { aboutFunc } from "./pages/_about";
import { adventureFunc } from "./pages/_adventure";
import { contactFunc } from "./pages/_contact";
import { dreamFunc } from "./pages/_dream";
import { homeFunc } from "./pages/_home";
import { homeGsapFunc } from "./pages/_home-gsap";
import { lifebookFunc } from "./pages/_life-book";
import { lifemusicFunc } from "./pages/_life-music";
import { lifeFunc } from "./pages/_life";
import { singleworksFunc } from "./pages/_single-works";
import { singleadventureFunc } from "./pages/_single-adventure";
import { visionFunc } from "./pages/_vision";
import { wineFunc } from "./pages/_wine";
import { worksFunc } from "./pages/_works";

$(window).on("load", function () {
  // securityFunc();
  commonFunc();
});

const GSAPTOP = document.querySelector(".p-home");
const BOOK = document.querySelector(".p-life-book");
const DREAM = document.querySelector(".p-dream");
const CONTACT = document.querySelector(".p-contact");
const ABOUT = document.querySelector(".p-about__first");
const LIFE = document.querySelector("#splash-life");
const WORKS = document.querySelector(".p-works");
const WINE = document.querySelector(".p-wine");
const SINGLEWORKS = document.querySelector(".p-single-works");
const VISION = document.querySelector(".p-vision");
const SINGLEADVENTURE = document.querySelector(".p-single-adventure");
const MUSIC = document.querySelector(".p-life-music");
const ADVENTURE = document.querySelector(".p-adventure");
const AUDIO = document.querySelector(".p-audio__button");
const TOPBUTTON = document.querySelector("#page-top");
const CHANGESCREEN = document.querySelector("#splash-logo");
const TOP = document.querySelector(".p-home");
const NAVMENU = document.querySelector("#navArea");
const NAVMENUS = document.querySelector("#navAreas");

if (TOP !== null) {
  homeFunc();
}
if (NAVMENU !== null) {
  navmenuFunc();
}
if (NAVMENUS !== null) {
  navmenusFunc();
}
if (TOPBUTTON !== null) {
  topbuttonFunc();
}
if (GSAPTOP !== null) {
  homeGsapFunc();
}

if (CHANGESCREEN !== null) {
  changescreenFunc();
}
if (AUDIO !== null) {
  audioFunc();
}
if (DREAM !== null) {
  dreamFunc();
}
if (VISION !== null) {
  visionFunc();
}
if (ADVENTURE !== null) {
  adventureFunc();
}
if (MUSIC !== null) {
  lifemusicFunc();
}
if (WINE !== null) {
  wineFunc();
}
if (WORKS !== null) {
  worksFunc();
}

if (SINGLEADVENTURE !== null) {
  singleadventureFunc();
}
if (SINGLEWORKS !== null) {
  singleworksFunc();
}

if (LIFE !== null) {
  lifeFunc();
}

if (BOOK !== null) {
  lifebookFunc();
}

if (CONTACT !== null) {
  contactFunc();
}

if (ABOUT !== null) {
  aboutFunc();
}
