export function singleadventureFunc() {
  const swiper = new Swiper(".swiper", {
    loop: true,
    direction: "horizontal",
    centeredSlides: true,
    slidesPerView: 3.5,
    grabCursor: true,
    passiveListeners: true,
    speed: 2700,
    autoplay: {
      delay: 0, // 途切れなくループ
      disableOnInteraction: false,
    },
    mousewheel: true,
    breakpoints: {
      350: {
        speed: 800,
      },

      700: {
        speed: 1800,
      },

      900: {
        speed: 2700,
      },
    },
  });
}
