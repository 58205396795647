export function dreamFunc() {
  // GSAP

  //////////////////////////////////////

  let gsap_1 = document.querySelector(".gsap_1");
  let gsap_2 = document.querySelector(".gsap_2");
  let gsap_3 = document.querySelector(".gsap_3");
  let gsap_4 = document.querySelector(".gsap_4");
  let gsap_5 = document.querySelector(".gsap_5");
  let gsap_6 = document.querySelector(".gsap_6");
  let gsap_7 = document.querySelector(".gsap_7");
  let gsap_8 = document.querySelector(".gsap_8");
  let gsap_9 = document.querySelector(".gsap_9");
  let gsap_10 = document.querySelector(".gsap_10");

  gsap.fromTo(
    [gsap_1],
    {
      y: 50,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-dream__long-dream-sentence",
        scrub: true,
        start: "top 65%",
        end: "top 40%",
        // markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    [gsap_2],
    {
      y: 50,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-dream__long-dream-sentence",
        scrub: true,
        start: "top 55%",
        end: "top 25%",
        //   markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    [gsap_3],
    {
      y: 50,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-dream__long-dream-sentence",
        scrub: true,
        start: "top 40%",
        end: "top 20%",
        //   markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    [gsap_4],
    {
      y: 50,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-dream__long-dream-sentence",
        scrub: true,
        start: "10% 30%",
        end: "10% 15%",
        //   markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    [gsap_5],
    {
      y: 50,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-dream__long-dream-sentence",
        scrub: true,
        start: "30% 30%",
        end: "30% 0%",
        //   markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    [gsap_6],
    {
      y: 50,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-dream__long-dream-sentence",
        scrub: true,
        start: "50% 30%",
        end: "50% 0%",
        //   markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    [gsap_7, gsap_8, gsap_9],
    {
      y: 50,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-dream__long-dream-sentence",
        scrub: true,
        start: "65% 30%",
        end: "65% 0%",
        //   markers: true,
        toggleActions: "play none none none",
      },
    }
  );
  gsap.fromTo(
    [gsap_10],
    {
      y: 50,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      scrollTrigger: {
        trigger: ".p-dream__long-dream-sentence",
        scrub: true,
        start: "85% 30%",
        end: "85% 0%",
        //   markers: true,
        toggleActions: "play none none none",
      },
    }
  );

  let gsap_red = document.querySelector(".gsap_red");
  let gsap_red2 = document.querySelector(".gsap_red2");
  let gsap_red3 = document.querySelector(".gsap_red3");

  gsap.fromTo(
    [gsap_red, gsap_red2, gsap_red3],
    {
      color: "#12265f",
    },
    {
      color: "#f20e0e",
      scrollTrigger: {
        trigger: ".gsap-red-group",
        scrub: true,
        start: "0% 70%",
        end: "0% 30%",
        //   markers: true,
        toggleActions: "play none none none",
      },
    }
  );

  var width = $(window).width();
  if (width > 1200) {
    luxy.init({
      wrapper: "#luxy",
      targets: ".luxy-el",
      wrapperSpeed: 0.08,
    });
  }

  // let path = document.querySelector("path");
  // let pathLength = document.getTotalLength();

  // path.style.strokeDasharray = pathLength + " " + pathLength;
  // path.style.strokeDashoffset = pathLength;

  // window.addEventListener("scroll", () => {
  //   let scrollPercentage =
  //     document.documentElement.scrollTOP + document.body.scrollTop;

  //   let drawLength = pathLength * scrollPercentage;
  //   path.style.strokeDashoffset = pathLength - drawLength;
  // });

  // gsap.fromTo(
  //   ".gsap-1",
  //   {
  //     scale: 1,
  //   },
  //   {
  //     scale: 0.5,

  //     scrollTrigger: {
  //       trigger: ".p-life-music__second",
  //       scrub: true,
  //       start: "0% 100%",
  //       //   markers: true,
  //       end: "20% 40%",
  //       //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //       toggeleActions: "play reset resume reset",
  //     },
  //   }
  // );
  //   gsap.fromTo(
  //     ".p-life-music__second",
  //     {
  //       opacity: 0,
  //     },
  //     {
  //       opacity: 1,

  //       scrollTrigger: {
  //         trigger: ".p-life-music__second",
  //         scrub: true,
  //         start: "20% 100%",
  //         //   markers: true,
  //         end: "20% 40%",
  //         //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //         toggeleActions: "play reset resume reset",
  //       },
  //     }
  //   );
  //   gsap.fromTo(
  //     ".p-life-music__second",
  //     {
  //       scale: 0.5,
  //     },
  //     {
  //       scale: 1,

  //       scrollTrigger: {
  //         trigger: ".p-life-music__second",
  //         scrub: true,
  //         start: "0% 100%",
  //         //   markers: true,
  //         end: "20% 40%",
  //         //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //         toggeleActions: "play reset resume reset",
  //       },
  //     }
  //   );
  //   gsap.fromTo(
  //     ".p-life-music__second",
  //     {
  //       scale: 1,
  //     },
  //     {
  //       scale: 0.5,

  //       scrollTrigger: {
  //         trigger: ".p-life-music__third",
  //         scrub: true,
  //         start: "0% 100%",
  //         // markers: true,
  //         end: "20% 40%",
  //         //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //         toggeleActions: "play reset resume reset",
  //       },
  //     }
  //   );
  //   gsap.fromTo(
  //     ".p-life-music__second",
  //     {
  //       opacity: 1,
  //     },
  //     {
  //       opacity: 0,

  //       scrollTrigger: {
  //         trigger: ".p-life-music__third",
  //         scrub: true,
  //         start: "0% 100%",
  //         // markers: true,
  //         end: "20% 40%",
  //         //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //         toggeleActions: "play reset resume reset",
  //       },
  //     }
  //   );

  //   gsap.fromTo(
  //     ".p-life-music__third",
  //     {
  //       opacity: 0,
  //     },
  //     {
  //       opacity: 1,

  //       scrollTrigger: {
  //         trigger: ".p-life-music__third",
  //         scrub: true,
  //         start: "0% 100%",
  //         //   markers: true,
  //         end: "20% 40%",
  //         //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //         toggeleActions: "play reset resume reset",
  //       },
  //     }
  //   );
  //   gsap.fromTo(
  //     ".p-life-music__third",
  //     {
  //       scale: 0.5,
  //     },
  //     {
  //       scale: 1,

  //       scrollTrigger: {
  //         trigger: ".p-life-music__third",
  //         scrub: true,
  //         start: "0% 100%",
  //         //   markers: true,
  //         end: "20% 40%",
  //         //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //         toggeleActions: "play reset resume reset",
  //       },
  //     }
  //   );

  //   gsap.fromTo(
  //     ".p-life-music__third",
  //     {
  //       scale: 1,
  //     },
  //     {
  //       scale: 0.5,

  //       scrollTrigger: {
  //         trigger: ".p-life-music__forth",
  //         scrub: true,
  //         start: "0% 100%",
  //         // markers: true,
  //         end: "20% 40%",
  //         //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //         toggeleActions: "play reset resume reset",
  //       },
  //     }
  //   );
  //   gsap.fromTo(
  //     ".p-life-music__third",
  //     {
  //       opacity: 1,
  //     },
  //     {
  //       opacity: 0,

  //       scrollTrigger: {
  //         trigger: ".p-life-music__forth",
  //         scrub: true,
  //         start: "0% 100%",
  //         // markers: true,
  //         end: "20% 40%",
  //         //トリガー要素の基準となる位置　　　ブラウザの画面内の位置（％、+=300pxなどで指定可能）
  //         toggeleActions: "play reset resume reset",
  //       },
  //     }
  //   );
}
